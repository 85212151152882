const webfont = document.createElement('link');
webfont.rel = 'stylesheet';
webfont.href = 'https://use.typekit.net/pvh4jyy.css';
webfont.onload = function () {
  document.body.classList.add('loaded-en-fonts');
};

const ssCommunity = document.createElement('link');
ssCommunity.rel = 'stylesheet';
ssCommunity.href = '/wp-content/themes/do-it/fonts/ss-community.css';
ssCommunity.onload = function () {
  document.body.classList.add('loaded-community-icons');
};

const ssSocial = document.createElement('link');
ssSocial.rel = 'stylesheet';
ssSocial.href = '/wp-content/themes/do-it/fonts/ss-social-regular.css';
ssSocial.onload = function () {
  document.body.classList.add('loaded-social-icons');
};

document.body.appendChild(webfont);
document.body.appendChild(ssCommunity);
document.body.appendChild(ssSocial);
