//for IE, Edge polyfill
// import smoothscroll from 'smoothscroll-polyfill';
import ScrollOut from 'scroll-out';

// smoothscroll.polyfill();

ScrollOut({
  targets: '.page-header',
  onShown: function (element, ctx, scrollingElement) {
    document.body.classList.remove('page-header-out');
  },

  onHidden: function (element, ctx, scrollingElement) {
    document.body.classList.add('page-header-out');
  },
});
