const indexLists = document.querySelector('.page-index__lists');
const indexListTitle = document.querySelector('.page-index__title');
const indexListCloseButton = document.querySelector('.page-index__close-button');
let h2Lists = document.querySelectorAll('.page-contents h2');
    h2Lists = Array.prototype.slice.call(h2Lists, 0);

let indexListContent = '';

if (h2Lists.length == 0) {
  document.querySelector('.page-index').textContent = null;
}

h2Lists.forEach(function (h2, index) {
  if (!indexLists) return false;
  const id = 'index' + index;
  indexListContent += '<li><a href="#'+id+'">'+h2.innerText+'</a></li>';
  h2.innerHTML = '<a id="'+id+'" class="page-anchor"></a>'+h2.innerText;
  if (index === h2Lists.length - 1) {
    indexLists.insertAdjacentHTML('afterbegin', indexListContent);

    indexListTitle.addEventListener('click', function (e) {
      indexLists.classList.add('open');
    });

    indexListCloseButton.addEventListener('click', function (e) {
      e.preventDefault();
      indexLists.classList.remove('open');
    });
  }
});
