//for IE, Edge polyfill
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();
import objectFitImages from 'object-fit-images';
objectFitImages();

import './_home.js';
import './_fonts.js';
import './_add-index.js';
import './_scroll-management.js';
import './_global-nav.js';
