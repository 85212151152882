import { tns } from '../../node_modules/tiny-slider/src/tiny-slider';

(function () {

  if (!document.body.classList.contains('home')) return this;

  const slider = tns({
    autoHeight: 'auto',
    autoplay: true,
    autoplayButtonOutput: false,
    container: '.home-cover-container .js-wpv-view-layout',
    controls: false,
    items: 1,
    lazyload: false,
    lazyloadSelector: '.home-cover__image',
    loop: true,
    nav: false,
    mode: 'gallery',
    slideBy: 'page',
    speed: 1000,
  });

})();
